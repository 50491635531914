import FieldNames from '../fields'
import Integrations from '../../../integrations'
import Yup from '../../../yup'

export default function validationSchema() {
  return [
    // step 1
    {
      [FieldNames.PL]: Yup.number().required(),
      closed: Yup.object({
        reason: Yup.string().required(),
        comment: Yup.string().required().trim().max(80000),
      }),
    },
    // step 2
    {
      connectors: Yup.object({
        telegram: Yup.object({
          signalClosed: Integrations.Telegram.Connectors.Validators.connectorDataValidator().required(),
        }).nullable(),
      }).required(),
    },
  ]
}
